/* eslint class-methods-use-this: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import VimeoPlayer from '@u-wave/react-vimeo'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import sendEvent from '../../../helpers/sendAnalytics';

const VideoBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > div {
    width: 100%;
  }
`

const Vimeo = styled(VimeoPlayer)`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

class VideoBlock extends React.Component {
  constructor(props) {
    super(props)

    this.quarterPassed = [];
    this.halfPassed = [];
    this.threeQuarterPassed = [];
    this.watched = [];

    this.onPlay = props.onPlay;
    this.sendEvents = props.events;
    this.eventDetails = props.eventDetails;

    this.onTimeUpdate.bind(this);
  }

  onStateChange(event, provider, url, videoId) {
    if (this.sendEvents) {
      if (provider === 'youtube') {
        // Youtube
        if (event.data === 1) {
          // Playing
          // if (url)
        }
      } else if (provider === 'vimeo') {
        if (this.eventDetails) {
          sendEvent({
            ga: this.eventDetails.play
          });
        } else {
          // Vimeo
          // Velour Video
          // eslint-disable-next-line no-lonely-if
          if (url === 'https://vimeo.com/305104723') {
            // Velour Video
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_View',
                action: 'Velour_Video_Play'
              }
            })
          } else if (url === 'https://vimeo.com/305106198') {
            // Tiger Towel EN Video
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_View',
                action: 'Tiger_Towel_Video_Play'
              }
            })
          } else if (url === 'https://vimeo.com/305106322') {
            // Tiger Towel FR video
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_View',
                action: 'Tiger_Towel_Video_Play_FR'
              }
            })
          } else {
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_View',
                action: `Video_Play_${videoId}`
              }
            });
          }
        }
      }
    }

    if (provider === 'vimeo') {
      this.onPlay();
    }
  }

  onTimeUpdate(event, provider, url, videoId) {
    if (this.sendEvents) {
      if (provider === 'vimeo') {
        if (event.percent > 0.25 && event.percent < .5 && !this.quarterPassed.includes(videoId)) {
          if (this.eventDetails) {
            sendEvent({
              ga: this.eventDetails.progress_25
            });
          } else {
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_Progress',
                action: `Video_25%_${videoId}`
              }
            });
          }

          this.quarterPassed.push(videoId);
        }

        if (event.percent > 0.5 && event.percent < .75 && !this.halfPassed.includes(videoId)) {
          if (this.eventDetails) {
            sendEvent({
              ga: this.eventDetails.progress_50
            });
          } else {
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_Progress',
                action: `Video_50%_${videoId}`
              }
            });
          }

          this.halfPassed.push(videoId);
        }

        if (event.percent > 0.75 && event.percent < 1 && !this.threeQuarterPassed.includes(videoId)) {
          if (this.eventDetails) {
            sendEvent({
              ga: this.eventDetails.progress_75
            });
          } else {
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_Progress',
                action: `Video_75%_${videoId}`
              }
            });
          }

          this.threeQuarterPassed.push(videoId);
        }

        if (event.percent === 1 && !this.watched.includes(videoId)) {
          if (this.eventDetails) {
            sendEvent({
              ga: this.eventDetails.progress_100
            });
          } else {
            sendEvent({
              ga: {
                category: 'Engagement',
                label: 'Video_Progress',
                action: `Video_100%_${videoId}`
              }
            });
          }

          this.watched.push(videoId);
        }
      }
    }
  }

  getProvider(url) {
    if (url.indexOf("youtu") !== -1) {
      return 'youtube';
    }

    return 'vimeo';
  }

  getVideoId(url, provider) {
    if (provider === 'youtube') {
      const match = url.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);

      return (match && match[2].length === 11) ? match[2] : '';
    }

    const matches = url.match(/vimeo.com\/(\d+)/);

    return matches[1];
  }

  render() {
    const { url, modification, height, responsive, autoplay, className, classOverride } = this.props;
    let opts = {};
    if (responsive) {
      opts = {
        width: '100%',
        height: `100%`
      };
    } else {
      opts = {
        width: '100%',
        height: `${height}px`
      };
    }
    const videoProvider = this.getProvider(url)
    const videoId = this.getVideoId(url, videoProvider)

    return (
      <VideoBlockWrapper modification={modification} responsive={responsive} className={className}>
        {
          videoProvider === 'youtube'
          ?
            (
              <YouTube
                videoId={videoId}
                opts={opts}
                onStateChange={(e) => this.onStateChange(e, videoProvider, url, videoId)}
              />
            )
          :
            (
              <Vimeo
                className={classOverride}
                video={videoId}
                height={opts.height}
                onPlay={(e) => this.onStateChange(e, videoProvider, url, videoId)}
                onTimeUpdate={(e) => this.onTimeUpdate(e, videoProvider, url, videoId)}
                responsive={responsive}
                autoplay={autoplay}
                muted={autoplay}
                controls={!autoplay}
                background={autoplay}
                loop={autoplay}
              />
            )
        }
      </VideoBlockWrapper>
    )
  }
}

VideoBlock.propTypes = {
  url: PropTypes.string.isRequired,
  modification: PropTypes.string,
  height: PropTypes.number,
  responsive: PropTypes.bool,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  onPlay: PropTypes.func,
  events: PropTypes.bool,
  eventDetails: PropTypes.exact({
    play: PropTypes.exact({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string
    }),
    progress_25: PropTypes.exact({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string
    }),
    progress_50: PropTypes.exact({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string
    }),
    progress_75: PropTypes.exact({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string
    }),
    progress_100: PropTypes.exact({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string
    })
  })
}

VideoBlock.defaultProps = {
  modification: '',
  height: 462,
  responsive: false,
  autoplay: false,
  className: '',
  onPlay: () => {},
  events: true,
  eventDetails: null
}

export const VideoBlockFragment = graphql`
  fragment videoBlockFragment on VideoBlock {
    id
  }
`

export default VideoBlock
